import React, { useEffect } from "react";
import FocusLock from "react-focus-lock";
import { Backdrop, ModalWrapper } from "./styles";
import { isBrowser } from "@website-builder/utilities/utils/utils.js";
import { Icon } from "@website-builder/ui/shared/elements";

const Modal = ({
	disableBackdropClick = false,
	onClose = () => {},
	children,
	show = false,
	className = "",
	media = false,
	isHubspotFormModal = false,
	...restProps
}) => {
	useEffect(() => {
		if (isBrowser) {
			window.document.body.style.overflow = show ? "hidden" : "unset";
		}

		return () => {
			window.document.body.style.overflow = "unset";
		};
	}, [show]);

	const stopPropagation = (e) => e.stopPropagation();
	let color =
		media || isHubspotFormModal
			? "var(--primary-neutral-white)"
			: "var(--primary-neutral-nightshade-800)";

	const handleEscapePress = (event) => {
		if (event.key === "Escape") {
			onClose();
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleEscapePress, true);
		return () => {
			document.removeEventListener("click", handleEscapePress, true);
		};
	}, []);

	return (
		<>
			{show && (
				<Backdrop
					onKeyDown={handleEscapePress}
					onClick={disableBackdropClick ? null : onClose}
					show={show}
				>
					<FocusLock returnFocus={true}>
						<ModalWrapper
							onClick={stopPropagation}
							className={className}
							show={show}
							$media={media}
							role="dialog"
							aria-modal="true"
							{...restProps}
						>
							<button className="closeButtonWrapper" onClick={onClose}>
								<Icon
									iconName="xMark32"
									stroke={color}
									fill={color}
									alt="close modal"
								/>
							</button>
							{children}
						</ModalWrapper>
					</FocusLock>
				</Backdrop>
			)}
		</>
	);
};

export default Modal;
