import {
	MOBILE_L_BP,
	TABLET_M_BP,
} from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import styled from "styled-components";

export const Backdrop = styled.div`
	width: 100%;
	height: ${(props) => (props.show ? "100%" : "0")};
	opacity: ${(props) => (props.show ? "1" : "0")};
	position: fixed;
	z-index: 9999;
	left: 0;
	top: 0;
	background-color: rgba(0, 0, 0, 0.5);
	overflow-y: auto;
	transition:
		opacity 0.3s,
		height 0.3s;
`;

export const ModalWrapper = styled.div`
	display: inline-block;
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	top: ${(props) =>
		props.$media ? "0" : props.isHubspotFormModal ? "40px" : "120px"};
	background-color: ${(props) =>
		props.$media || props.isHubspotFormModal
			? "transparent"
			: "var(--primary-neutral-white)"};
	border-radius: 8px;
	padding: 80px 104px;
	min-width: 300px;

	.closeButtonWrapper {
		text-align: right;
		position: absolute;
		right: 40px;
		top: ${(props) => (props.isHubspotFormModal ? "80px" : "40px")};
		cursor: pointer;
		background: transparent;
		border: none;
		padding: 0;
	}

	@media only screen and (max-width: ${TABLET_M_BP}px) {
		padding: 60px 10%;
		width: 80%;
	}

	@media only screen and (max-width: ${MOBILE_L_BP}px) {
		width: 100%;
		border-radius: 0;
	}
`;
